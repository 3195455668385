type OnChangeCallback = (url: string) => void;

class UrlChangeTracker {
  public path: string;
  public track(onChange: OnChangeCallback): void {
    if (!history.pushState || !window.addEventListener) {
      return;
    }
    this.path = this.getPath();
    history.pushState = this.wrapWithHandleUrlChange(history, onChange, history.pushState);
    history.replaceState = this.wrapWithHandleUrlChange(history, onChange, history.replaceState);

    window.addEventListener('hashchange', () => this.handleUrlChange(onChange, true));
    window.addEventListener('popstate', () => this.handleUrlChange(onChange, true));
  }
  private wrapWithHandleUrlChange(object: any, onChange: OnChangeCallback, originalMethod: (...args: any) => any) {
    return (...args: any) => {
      originalMethod.apply(object, args);
      this.handleUrlChange(onChange, true);
    };
  }

  private getPath() {
    return location.href;
  }

  private handleUrlChange(onChange: OnChangeCallback, historyDidUpdate) {
    setTimeout(() => {
      const oldPath = this.path;
      const newPath = this.getPath();

      if (oldPath !== newPath) {
        this.path = newPath;
        if (historyDidUpdate) {
          onChange(newPath);
        }
      }
    }, 0);
  }
}

const tracker = new UrlChangeTracker();

export const trackUrlChanges = (onChange: OnChangeCallback): void => {
  tracker.track(onChange);
};
