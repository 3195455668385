export interface UserInfo {
  userId: string;
  isAnonymous: boolean;
}

export interface AuthConfig {
  issuer: string;
}

export const AUTH_ERROR_RESULT_CODES = [
  'popup_closed', 'profile_access_denied', 'unknown_error'
] as const;

export type AuthErrorResultType = typeof AUTH_ERROR_RESULT_CODES[number];

export interface AuthErrorResult {
  type: AuthErrorResultType;
}

export interface AuthResult {
  code: string;
  error?: AuthErrorResult;
}

export interface AuthParams {
  clientId: string;
}

export interface AuthApi {
  isSsoDialogEnabled(): boolean;
  getUserInfo(): UserInfo;
  getAuthConfig(): AuthConfig;
  tryAuth(params: AuthParams): Promise<AuthResult>;
}
